@import "minima";

body {
    font-size: 18px;
}

img#header-logo {
    display: block;
    width: 200px;
    margin-top: 8px;
}

.site-header {
    border-top: none;
}

.center-image
{
    margin: 0 auto;
    display: block;
}

#license-cc {
    font-size: x-small;
    float: right;
}

/* tagging */
ul.tags {
    margin-left: unset;
}

a.tag {
    color: white;
    background: grey;
    border-radius: 5px;
    padding-right: 3px;
    padding-left: 3px;
    padding-bottom: 3px;
    font-size: smaller;
    text-decoration: none;
    opacity: 0.7;
}

span.nobr {
    white-space: nowrap;
}

table th, table td {
    padding: unset;
}

.gutter.gl {
    width: 5px;
}